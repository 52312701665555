<template>
    <h1>自定义路由</h1>
</template>

<script>
    export default {
        name: "routers"
    }
</script>

<style scoped>

</style>
